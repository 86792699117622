/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

declare const chrome: any;

@Injectable({
  providedIn: 'root'
})
export class WhatsappApiService {
  private isExtension = false;
  private port;
  private callbacks = {};
  private extId;
  private _enabled: Subject<any>;

  constructor() {
    this.isExtension = true;
    if(this.isExtension){
      this._enabled = new Subject();
      const interval = setInterval(() => {
        this.init();
        if (this.extId) {
          clearInterval(interval);
        }
      }, 2000);
    }
  }

  public get enabled() {
    return this._enabled;
  }

  extConnect() {
    if(!chrome.runtime){
      return;
    }
    const extConnect2 = ()=> this.extConnect();
    try {
        this.port = chrome.runtime.connect(this.extId, { name: 'notifications' });
        this.port.onMessage.addListener(msg =>{
            //console.log(msg.type, msg);
            if(this.callbacks[msg.id]){
              this.callbacks[msg.id](msg.data, msg.error);
            }
        });

        this.port.onDisconnect.addListener(evt => {
            this.port = null;
            this._enabled.next(false);
            console.log('Desconectado');
            setTimeout(extConnect2, 5000);
        });
        this._enabled.next(true);
        console.log('Reconectado');

    } catch (e) {
        console.log('Error de conexión: ', e.message);
        setTimeout(extConnect2, 5000);
    }
  }

  notifyMessage(message, timeout=null) {
    //console.log('notifyMessage', message);
    if(timeout === null){
      timeout = 15000;
    }
    return new Promise((resolve, reject) =>{
      try{
        if (this.port) {
          const interval = setTimeout(()=>{
            delete this.callbacks[message.id];
            reject('Timeout error');
          }, timeout);
          this.callbacks[message.id] = (r, e) => {
            clearInterval(interval);
            delete this.callbacks[message.id];
            if(e == null){
              resolve(r);
            } else {
              reject(e);
            }
          };
          this.port.postMessage(message);
        } else {
          reject('Closed port');
        }
      } catch(e){
          reject(e);
      }
    });
  }

  async sendWPMessage(country, phone, message): Promise<any> {//id,
    if(this.isExtension && this.extId){
      const id = 'send-message-' + new Date().getTime();
      const msg = { id, type: 'send-message', country, phone, message };
      return await this.notifyMessage(msg, 5000);
     /*
     console.log('send-message', country, phone, message);
     return {
        wp_id: Math.random(),
        t: new Date().getTime()
     };
     */
    } else {
      throw new Error('No inicializado');
    }
  }

  async getMe(){
    //console.log('getMe', this.isExtension, this.extId);
    if(this.isExtension && this.extId){
      const id = 'me-' + new Date().getTime();
      const msg = { id, type: 'get-me' };
      return await this.notifyMessage(msg, 5000);
    } else {
      throw new Error('No inicializado');
    }
  }

  async getStatus(msgs) {
    if(this.isExtension && this.extId){
      const id = 'status-' + new Date().getTime();
      const msg = { id, type: 'get-status', messages: msgs };
      return await this.notifyMessage(msg, 5000);
    } else {
      throw new Error('No inicializado');
    }
  }

  private init() {
    if (!this.extId) {
      this.extId = 'oihfnnnbopblaaclibdgnicmohflabci';
      this.extConnect();
    }
  }
}
