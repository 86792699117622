// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCGRjeEkb5pKRClcALJEcAp9qG4A9u5RY0',
    authDomain: 'wamk.rt4apps.com',
    projectId: 'wapp-338215',
    storageBucket: 'wapp-338215.appspot.com',
    messagingSenderId: '82622841164',
    appId: '1:82622841164:web:680b5263a4fecbb0d58fe9'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
