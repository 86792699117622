/* eslint-disable new-parens */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */

export class Message {
    static STATUS_CREATED = 1;
    static STATUS_ERROR = 2;
    static STATUS_PENDING = 3;
    static STATUS_SENT = 4;
    static STATUS_RECEIVED = 5;
    static STATUS_READ = 6;
    static STATUS_CLICKED = 7;

    //id: number;
    //_campaignId: string;
    //numRow: number;
    phone: string;
    data: any;
    status: number;

    getPath(): string {
        //return super.getPath().replace('/messages', `/campaigns/${this._campaignId}/messages`);
        return null;
    }
    getByCampaignIdAndRowId(campaignId: string, nextRow: number){
        /*
        //this._campaignId = campaignId;
        const path = this.getPath();
        return FireModel.db.firestore.collection(path).where('numRow', '==', nextRow)
            .get()
            .then(querySnapshot=>{
                if(!querySnapshot.empty){
                    const doc = querySnapshot.docs[0];
                    const data = (new (this.constructor as any)).fromJSON(doc.data());
                    data._id = doc.id;
                    return data;
                }
                return null;
            });
            */
           return null;
    }
    /*
    getOutdatedState(){
        return Message.dbService.getAllByIndex(this.store, 'message_status',
            IDBKeyRange.bound(Message.STATUS_SENT, Message.STATUS_RECEIVED, false, false))
            .toPromise()
            .then((messages: any[])=>{
                const status = {};
                for(const message of messages){
                    status[message.wp_id] = message.status-Message.STATUS_SENT+1;
                }
                return status;
            });
    }
    */

    //Serialize methods
    fromJSON(input: any): this {
        if(input){
            return Object.assign(this, input);
        } else {
            return null;
        }

    }

    toJSON() {
        const data: any = {};
        const keys = Object.keys(this).filter(k=>k[0] !== '_');
        for (const key of keys) {
          data[key] = this[key];
        }
        return data;
      }
}
