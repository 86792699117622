import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

import { environment } from '../environments/environment';

import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { EditorModule } from '@tinymce/tinymce-angular';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimeagoModule } from 'ngx-timeago';

const dbConfig: DBConfig = {
  name: 'rt-wapi',
  version: 1,
  objectStoresMeta: [
    {
      store: 'settings',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: []
    },
    {
      store: 'campaign',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'campaign_status', keypath: 'status', options: { unique: false } },
      ]
    },
    {
      store: 'message',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'message_campaignId', keypath: 'campaignId', options: { unique: false } },
        { name: 'message_status', keypath: 'status', options: { unique: false } },
        { name: 'message_wp_id', keypath: 'wp_id', options: { unique: false } },
        { name: 'message_campaign_row_id', keypath: ['campaignId', 'numRow'], options: { unique: false } },
      ]
    }
  ]
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    EditorModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TimeagoModule.forRoot()
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
