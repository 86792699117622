import { Injectable } from '@angular/core';
import { Router, CanActivate, CanDeactivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanDeactivate<CanComponentDeactivate> {
    loggedIn: boolean = null;
    constructor(private router: Router, private authservice: AuthService) {
        this.authservice.getAuthStateSub()
            .subscribe(user => {
                this.loggedIn = user ? true : false;
                if (!user) {
                    this.router.navigate(['/login']);
                }
            });
    }

    canDeactivate(
    ): Observable<boolean> | boolean {
        return true;
    }

    canActivate(
        next: ActivatedRouteSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const currentPath = next.routeConfig.path;
        console.log('loggedIn', this.loggedIn);
        if (this.loggedIn !== null) {
            return this.loggedIn;
        } else {
            return false;
        }
    }
}
